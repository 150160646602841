export default {
	data: {
		selectedImageIndex: undefined
	},
	beforeMount: function () {
		//console.warn("ha", window.location.search);
		var url = new URL(window.location.href);
		var params = {};
		for (let key of url.searchParams.keys()) {
			//console.log("p", key, url.searchParams.get(key));
			params[key] = url.searchParams.get(key);

		}
		this.searchParams = params;
	},
	mounted: function () {

		$('#showGalerie').click(function () {
			$('#showMap').removeClass('active');
			if (!$(this).hasClass("active")) {
				$('#showGalerie').addClass('active');
			}
			$('#facility-map').css({
				'z-index': '3'
			});
			$('.title-img').css({
				'z-index': '6'
			});

			$('.facility-nomap').css({
				'display': 'none'
			});

			$('.facility-nomap').css({
				'z-index': '3'
			});
		});

		$('#showMap').click(function () {
			$('#showGalerie').removeClass('active');
			if (!$(this).hasClass("active")) {
				$('#showMap').addClass('active');
			}
			$('#facility-map').css({
				'z-index': '6'
			});
			$('.title-img').css({
				'z-index': '3'
			});
			$('.facility-nomap').css({
				'display': 'table'
			});
			$('.facility-nomap').css({
				'z-index': '6'
			});
		});
	}

};