import V from 'voUtils/V.js';
import QuoteTools from 'voUtils/QuoteTools.js';

import {
	doReq
}
from 'voUtils/RestEx.js';
import {
	fireQuote, fireUnitAction, fireCheckout
}
from 'voUtils/TagEx.js';
import {
	find, filter, isEmpty, oneIs
}
from 'voUtils/tools.js';

import {
	getVoucher
}
from 'voUtils/VoucherStore.js';

import DateEx from 'voUtils/DateEx.js';

export default {

	data: {
		bookingData: {
			adults: 2,
			children: 0,
			childrenAges: undefined,
			babys: 0,
			petsCount: 0,
			from: undefined,
			till: undefined
		},
		action: undefined,
		step: undefined, //'booking',
		priceIsUpdated: false,
		showPriceDetails: false,
		quote: {},
		error: undefined,
		preview: undefined,
		summary: undefined,
		selectedImageIndex: undefined,
		showAllProps: false,
		showFullDesc: false,
		showFullTexts: false,
		scrollToPrices: false,
		voucher: getVoucher(),
		showAllFeedback: false,
		selectedTravelInsurance: 0,
		travelInsurancePlans: undefined,
		travelInsuranceTarget: 'test',
		bookingMode: undefined
	},

	created: function () {
		this.calListeners = [];
		this.priceListeners = [];
		this.QuoteTools = QuoteTools;

		var params = new URLSearchParams(new URL(window.location).search);
		for (let [k, v] of params) {
			if (oneIs(k, 'ref_adults', 'ref_children', 'ref_babys', 'ref_from', 'ref_till')) {
				this.bookingData[k.substring(4)] = v;
			}
		}

		var hash = window.location.hash;
		if (hash) {
			hash = hash.substring(1);

			let url = new URL(hash, 'https://www.v-office.com');
			let params = new URLSearchParams(url.search);

			for (let p of params) {
				if ((p[0] == 'children' || p[0] == 'babys') && !this.areChildrenWelcome()) {
					//ignore not welcome
				} else if (p[0] == 'petsCount' && !this.arePetsWelcome()) {
					//ignore not welcome
				} else {
					this.bookingData[p[0]] = p[1];
				}
			}
		}


		this.updatePrices();
	},
	computed: {
		showBookingButtons: function () {
			return this.quote && this.quote.mandatory && !this.priceIsUpdated && isEmpty(this.quote.errors) && !this.action;
		},
		canDoNext: function () {
			return this.quote && this.quote.mandatory && !this.priceIsUpdated && isEmpty(this.quote.errors) && this.action;
		},
		periodLabel: function () {
			let label = '';
			if (this.bookingData.from) {
				label = this.voFormatDate(this.bookingData.from, 'withDay');
			}
			if (this.bookingData.till) {
				label += ' - ' + this.voFormatDate(this.bookingData.till, 'withDay');
			}

			return label;
		},
		guestLabel: function () {
			var sd = this.bookingData;
			var amount = ((sd.adults || 0) - 0) + ((sd.children || 0) - 0) + ((sd.babys || 0) - 0);
			var label = amount + ' ' + this.voMsgPl('guest.pl', amount);
			if (sd.petsCount) {
				label += ', ' + sd.petsCount + ' ' + this.voMsgPl('pet.pl', sd.petsCount);

			}
			return label;
		},



	},
	mounted: function () {
		const el = document.getElementById("bookingSection2");
		const observer = new IntersectionObserver(this.showBookNow, {
			threshold: 0.05
		});
		if (el) {
			observer.observe(el);
		}

		$(".owl-carousel-unit").owlCarousel({
			items: 1,

			loop: true,
			center: true,
			autoplay: false,
			smartSpeed: 1000,
			dots: false,
			nav: true,
			responsive: {
				1000: {
					stagePadding: 50
				},
				1700: {
					stagePadding: 150
				},

				1800: {
					stagePadding: 330
				}
			}
		});
		this.$nextTick(function () {
			setTimeout(() => {
				this.scrollToPrice = true;
			}, 1500);
		});
	},
	updated: function () {
		$('[data-toggle="tooltip"]').tooltip();
	},
	methods: {
		showPicker() {
				//this.$nextTick(() => this.$refs.picker.$el.querySelector('input').focus());
				this.$nextTick(() => {
					this.$refs.picker.$el.querySelector("input").focus();
					this.$refs.picker.$el.querySelector("input").click();
				});

			},
			showBookNow: function (entries) {
				var el2 = document.getElementById("book-now");
				if (this.action !== 'booking') {
					if (!entries[0].isIntersecting) {
						//    console.log('nicht sichtbar!');
						el2.classList.add("is-visible");
					}
					if (entries[0].isIntersecting) {
						//    console.log('sichtbar!');
						el2.classList.remove("is-visible");
					}
				}
				if (this.action === 'booking') {
					el2.classList.remove("is-visible");
				}


			},

			getAction: function () {
				return this.action;
			},
			checkBrowserLanguage: function () {
				if (navigator.language && (navigator.language != 'de-DE' && navigator.language != 'de')) {
					return true;
				}
				return false;
			},
			checkVOFFICElang: function () {
				if (VOFFICE.lang === 'de') {
					return true;
				}
				return false;
			},
			selectTravelInsurance: function (plan) {
				this.selectedTravelInsurance = plan;
			},

			getBookingMode: function () {
				if (!this.bookingMode) {
					let el = document.querySelector("[data-unit-bookingmode]");
					if (el) {
						this.bookingMode = el.getAttribute('data-unit-bookingmode');
					}
				}
				return this.bookingMode;
			},

			getTravelInsurancePlans: function () {

				var data = this.bookingData;
				var canUseSamePlan = false;
				var prevSelectedPlan = this.selectedTravelInsurance;
				var bookingMode = this.getBookingMode();

				if (data.from && data.till && this.quote && this.quote.travelInsuranceIsSetup && bookingMode === "DIRECTLY") {

					var req = {
						fromdate: DateEx.interfaceFormat(data.from),
						tilldate: DateEx.interfaceFormat(data.till),
						adults: data.adults,
						children: data.children,
						babys: data.babys,
						unit: this.getUnitId(),
						totalBookingAmount: QuoteTools.getTotal(this.quote, 'all')
					};
					if (VOFFICE.travelInsurance && VOFFICE.travelInsurance.forceTravelInsuranceMode) {
						req.forceTravelInsuranceMode = VOFFICE.travelInsurance.forceTravelInsuranceMode;
					}
					console.log('***totalBookingAmount', req.totalBookingAmount);
					doReq('getTravelInsurancePlans', req)
						.then((res) => {
							this.travelInsuranceTarget = res.target ? res.target : 'test';
							var ix;

							console.log('getTravelInsurancePlans', res, this.travelInsuranceTarget);
							if (this.travelInsurancePlans) {
								canUseSamePlan = true;

								for (ix in res.plans) {
									if (!this.travelInsurancePlans[ix]) {
										canUseSamePlan = false;
									} else if (res.plans[ix].planid != this.travelInsurancePlans[ix].planid) {
										canUseSamePlan = false;
									}
								}
							}
							if (!canUseSamePlan) {
								if (prevSelectedPlan) {
									this.selectedTravelInsurance = null;
								}
								this.travelInsurancePlans = res.plans;

								if (prevSelectedPlan) {
									for (ix in travelInsurancePlans) {
										if (this.travelInsurancePlans[ix].name == prevSelectedPlan.name) {
											this.selectedTravelInsurance = this.travelInsurancePlans[ix];
										}
									}
								}
							}
						});
				}
			},
			checkVoucherCode: function (code) {
				if (code) {
					if (this.voucher && code === this.voucher.code) {
						return true;
					} else {
						return false;
					}

				} else {
					return true;
				}
			},
			togglePropClass: function () {
				this.showAllProps = !this.showAllProps;
				this.showFullDesc = !this.showFullDesc;
			},

			toggleTextClass: function () {
				this.showFullTexts = !this.showFullTexts;
			},

			addLine: function (quote, sl) {
				var incrementBy = 1;
				if (sl.service.id == 46709) {
					if (!QuoteTools.canRemoveLine(sl)) {
						console.log('canRemove:::' + QuoteTools.canRemoveLine(sl));
						console.log('bookingData.adults::' + this.bookingData.adults);
						incrementBy = this.bookingData.adults;
					}

				}

				for (let i = 0; i < incrementBy; i++) {
					QuoteTools.addLine(quote, sl);
				}

				this.$forceUpdate();
			},
			removeLine: function (quote, sl) {
				QuoteTools.removeLine(quote, sl);
				this.$forceUpdate();
			},
			clearPrices: function () {
				this.quote = {};
				this.summary = undefined;
				this.preview = undefined;
				this.previewQuote = undefined;
			},
			updatePrices: function (scrollToPricesCalc) {
				var data = this.bookingData;
				this.error = undefined;
				if (data.from && data.till) {

					this.priceIsUpdated = true;
					this.quote = {};

					var req = {
						fromdate: DateEx.interfaceFormat(data.from),
						tilldate: DateEx.interfaceFormat(data.till),
						adults: data.adults,
						children: data.children,
						babys: data.babys,
						petsCount: data.petsCount,
						unit: this.getUnitId()
					};

					req.includeServiceLimits = true;

					if (VOFFICE.quoteOptions) {
						Object.assign(req, VOFFICE.quoteOptions);
					}

					var voucher = getVoucher();

					if (voucher) {
						req.voucherCode = voucher.code;
					}

					if (VOFFICE.abtest) {
						req.abtest = VOFFICE.abtest;
					}

					if (data.childrenAges) {
						req.childrenAges = data.childrenAges;
					}

					if (VOFFICE.travelInsurance) {
						if (VOFFICE.travelInsurance.checkTravelInsuranceIsSetup) {
							req.checkTravelInsuranceIsSetup = true;

							if (VOFFICE.travelInsurance.forceTravelInsuranceMode) {
								req.forceTravelInsuranceMode = VOFFICE.travelInsurance.forceTravelInsuranceMode;
							}
						}
					}

					doReq('quotePrices', req)
						.then((res) => {

							this.quote = res.quote;
							this.priceIsUpdated = false;
							this.quote.optional.forEach(sl => {
								sl.optional = true;
								sl.amount = null;
								this.QuoteTools.updateTotal(sl);
							});
							this.quote.onsiteOptional.forEach(sl => {
								sl.onsiteOptional = true;
								sl.amount = null;
								this.QuoteTools.updateTotal(sl);
							});
							this.quote.usage.forEach(sl => {
								sl.usage = true;
								sl.amount = null;
								this.QuoteTools.updateTotal(sl);
							});

							if (res.quote.mandatory) {
								this.summary = find(res.quote.mandatory, function (sl) {
									return sl.service.type === 'SUMMARY';
								});

								this.preview = filter(res.quote.mandatory, function (sl) {
									return !VOFFICE.hideTypeOnPricePreview || VOFFICE.hideTypeOnPricePreview.indexOf(sl.service.type) == -1;
								});

								if (!this.preview || this.preview.length < 2) {
									this.preview = undefined;
									this.previewQuote = undefined;
								} else {
									this.previewQuote = {
										mandatory: this.preview,
										hasDiscounts: this.quote.hasDiscounts
									};
								}

							}

							this.getTravelInsurancePlans();

							fireQuote(this.getCart());

							if (scrollToPricesCalc) this.scrollToPriceCalc();

						})
						.catch(e => {
							this.priceIsUpdated = false;
							this.error = e;
						});


				}

			},
			getCart: function () {
				// workarround 
				this.quote.selectedTravelInsurance = this.selectedTravelInsurance;
				this.quote.travelInsuranceTarget = this.travelInsuranceTarget;

				return {
					action: this.action,
					quote: this.quote,
					booking: Object.assign({}, this.bookingData),
					unitId: this.getUnitId()
				};
			},

			goAction: function (action) {
				this.action = action;
				this.showPriceDetails = true;
				var similarUnits = document.getElementById("similar-units");
				if (similarUnits) {
					similarUnits.style.display = "none";
				}
				fireUnitAction(this.getCart());
			},

			putInCart: function () {
				// @todo pruefen, ob eine Versicherung gewählt wurde
				if (this.quote.travelInsuranceIsSetup) {
					if (this.selectedTravelInsurance == null) {
						console.log("Versicherung wählen!");
					}
				}
				this.step = 'booking';
				fireCheckout(this.getCart());
				window.scrollTo(0, 0);

			},
			cancelBooking: function () {
				this.step = undefined;
			},
			cancel: function () {
				this.action = undefined;
				var similarUnits = document.getElementById("similar-units");
				if (similarUnits) {
					similarUnits.style.display = "block";
				}
				this.showPriceDetails = false;

			},


			getUnitId: function () {
				if (!this.unitId) {
					let el = document.querySelector("[data-unit-id]");
					if (el) {
						this.unitId = parseInt(el.getAttribute('data-unit-id'), 10);
					}
				}
				return this.unitId;
			},
			areChildrenWelcome: function () {
				if (this.childrenWelcome == undefined) {
					let el = document.querySelector("[data-children-welcome]");
					if (el) {
						this.childrenWelcome = el.getAttribute('data-children-welcome') == '1';
					} else {
						this.childrenWelcome = false;
					}
				}
				return this.childrenWelcome;
			},
			arePetsWelcome: function () {
				if (this.petsWelcome == undefined) {
					let el = document.querySelector("[data-pets-welcome]");
					if (el) {
						this.petsWelcome = el.getAttribute('data-pets-welcome') == '1';
					} else {
						this.petsWelcome = false;
					}
				}
				return this.petsWelcome;
			},
			getCal: function (done) {
				if (this.cal) {
					console.log('kalendar schon da');
					done(this.cal);
				} else {
					this.calListeners.push(done);

					if (this.calListeners.length === 1) {

						var req = {
							unit: this.getUnitId()
						};

						doReq('getCal', req)
							.then((res) => {
								this.cal = res.cal;

								for (let l of this.calListeners) {
									l(res.cal);
								}
								this.calListeners.length = 0;
							});

					}
				}

			},
			getPrices: function (done) {
				if (this.prices) {
					console.log('Prices schon da');
					done(this.prices);
				} else {
					this.priceListeners.push(done);


					if (this.priceListeners.length === 1) {


						var req = {
							id: this.getUnitId()
						};


						var params = {
							'noCache': true
						};


						doReq('getPricelist', req, params)
							.then((res) => {
								this.prices = res.prices;


								for (let l of this.priceListeners) {
									l(res.prices);
								}
								this.priceListeners.length = 0;
							});


					}
				}
			},

			scrollToPriceCalc: function () {
				var position = $('#bookingSection').position();
				var position2 = $('#bookingSection2').position();
				var scrollToPos = 0;
				var time = 1000;


				if (position2.top === 0) {
					scrollToPos = position.top - 200;
				} else {
					scrollToPos = position2.top + 100;
				}


				$('html, body').animate({
					scrollTop: scrollToPos
				}, time);
			},
			showPricelist() {
				// pricelist-container
				this.pricelistVisible = true;
			},
			hidePricelist() {
				this.pricelistVisible = false;
			}

	},
	watch: {
		'bookingData.from': function () {
			if (!this.bookingData.till) {
				this.clearPrices();
			}
			this.getTravelInsurancePlans();

		},
		'bookingData.till': function () {
			this.getTravelInsurancePlans();
		}
	}
};